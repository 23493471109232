@use "@angular/material" as mat;
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Tailwind colour reference: https://tailwindcss.com/docs/customizing-colors

// Tailwind Indigo
$primary-palette: (
    50: #eef2ff,
    100: #e0e7ff,
    200: #c7d2fe,
    300: #a5b4fc,
    400: #818cf8,
    500: #6366f1,
    600: #4f46e5,
    700: #4338ca,
    800: #3730a3,
    900: #312e81,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    ),
);

// Tailwind Sky
$accent-palette: (
    50: #f0f9ff,
    100: #e0f2fe,
    200: #bae6fd,
    300: #7dd3fc,
    400: #38bdf8,
    500: #0ea5e9,
    600: #0284c7,
    700: #0369a1,
    800: #075985,
    900: #0c4a6e,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    ),
);

// Tailwind Red
$warn-palette: (
    50: #fef2f2,
    100: #fee2e2,
    200: #fecaca,
    300: #fca5a5,
    400: #f87171,
    500: #ef4444,
    600: #dc2626,
    700: #b91c1c,
    800: #991b1b,
    900: #7f1d1d,
    contrast: (
        50: rgba(black, 0.87),
        100: rgba(black, 0.87),
        200: rgba(black, 0.87),
        300: rgba(black, 0.87),
        400: white,
        500: white,
        600: white,
        700: white,
        800: white,
        900: white,
    ),
);

:root {
    // Tailwind Gray
    --gray-50: #f9fafb;
    --gray-100: #f3f4f6;
    --gray-200: #e5e7eb;
    --gray-300: #d1d5db;
    --gray-400: #9ca3af;
    --gray-500: #6b7280;
    --gray-600: #4b5563;
    --gray-700: #374151;
    --gray-800: #1f2937;
    --gray-900: #111827;

    @each $level, $colour in $primary-palette {
        @if $level != contrast {
            --primary-#{$level}: #{$colour};
        }
    }
    @each $level, $colour in $accent-palette {
        @if $level != contrast {
            --accent-#{$level}: #{$colour};
        }
    }
    @each $level, $colour in $warn-palette {
        @if $level != contrast {
            --warn-#{$level}: #{$colour};
        }
    }
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.all-component-typographies();
@include mat.elevation-classes();
@include mat.app-background();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$orchestrate-primary: mat.m2-define-palette($primary-palette, 800);
$orchestrate-accent: mat.m2-define-palette($accent-palette, 400);

// The warn palette is optional (defaults to red).
$orchestrate-warn: mat.m2-define-palette($warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$orchestrate-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $orchestrate-primary,
            accent: $orchestrate-accent,
            warn: $orchestrate-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// Including just the ones we use only decreases the style bundle by ~15kB, not worth it
// when we have to manually manage the list of components in here (since we use a lot of them)
@include mat.all-component-themes($orchestrate-theme);

.mat-mdc-dialog-surface {
    // Make the same as the body so things like cards and accordions display more nicely
    --mdc-dialog-container-color: #fafafa;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.orchestrate-container {
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem 1rem;
}

// TODO Move this to the module somehow?
.mat-loading > :not(mat-progress-spinner) {
    visibility: hidden;
}

.mat-loading .mat-mdc-progress-spinner {
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
}

mat-card-header.mat-mdc-card-header {
    padding-top: 8px;
}

.mat-mdc-card-content > .mat-divider {
    margin-left: -1rem;
    margin-right: -1rem;
}

// TODO Move to be imported in data grid component
ag-grid-angular {
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    overflow: hidden; // So border-radius applies to children

    .ag-layout-auto-height .ag-row-last {
        border-bottom: 0;
    }
}

.ag-theme-material {
    .ag-header-cell {
        padding-left: 16px;
        padding-right: 16px;
    }

    .ag-cell {
        padding-left: 15px;
        padding-right: 15px;
    }

    .ag-layout-auto-height .ag-center-cols-clipper {
        min-height: 0;
    }
}

.ag-header-cell.ag-no-left-header-padding {
    padding-left: 0;
}

.ag-cell-value {
    cursor: text;
}

.ag-charts-tooltip {
    font:
        14px Roboto,
        Helvetica Neue,
        sans-serif;

    .ag-charts-tooltip-heading {
        font-weight: 500;
    }
}

// Style all non Angular Material links
a:not([class*="mat-"]),
a.mat-mdc-tooltip-trigger {
    &:link {
        color: var(--accent-600);
        text-decoration: none;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
    }

    &:visited {
        color: var(--primary-800);
    }

    &.stealth-link {
        color: inherit;
    }
}

strong {
    font-weight: 500;
}

app-help-center-page p {
    text-align: justify;
}

// Ripped off mat-badge
.attachment-badge {
    right: 0.25rem;
    top: 0.5rem;
    width: 16px;
    height: 16px;
    line-height: 16px;
    font-size: 9px;
    position: absolute;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    transition: transform 200ms ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    pointer-events: none;
    background-color: var(--accent-400);
    color: white;
}
